import React, { useEffect, useState } from "react"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { useNavigate, useParams } from "react-router-dom"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { useTranslation } from "react-i18next"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { InfoContent, Wrapper } from "components/shared/InfoComponents"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import { ApplicationAccountType } from "@jobintrans/base-ui.types.api"
import { SentryService } from "services/SentryService"

const OfferApplicationAccountModal = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(["panel", "data"])
  const { offerId = "", applicationId = "" } = useParams()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<null | ApplicationAccountType>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const report = await ProfileOfferService.getOfferApplicationAccount(offerId, applicationId)
        setData(report)
        setLoading(false)
      } catch (e) {
        SentryService.error("[ERROR ProfileOfferService.getOfferApplicationAccount]:", e)
      }
    }

    fetchData()
  }, [])

  return (
    <Modal
      show={true}
      setShow={() => navigate(`/panel/offers/${offerId}/applications`)}
      type="center"
      icon="user"
      title={data ? `${data.firstName} ${data.lastName}` : ""}
      maxWidth="700px"
    >
      <>
        {loading || !data ? (
          <Box marginTop="XL" marginBottom="XL">
            <Spinner />
          </Box>
        ) : (
          <Wrapper>
            <InfoContent>
              {data?.subcategories && data?.subcategories.length > 0 && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="tag"
                  iconColor="primary"
                  sublabel={t(`offers.offer.candidates.account.${data.category}-subcategories`)}
                  width="100%"
                  label={data?.subcategories
                    ?.map((el: string) =>
                      t(el, {
                        ns: "data",
                      }),
                    )
                    .join(", ")}
                />
              )}
              {data.category === "others" && data?.truckTypes && data?.truckTypes.length > 0 && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="tag-driver"
                  iconColor="primary"
                  sublabel={t("offers.offer.candidates.account.truck-types")}
                  width="100%"
                  label={data?.truckTypes
                    ?.map((el: string) =>
                      t(el, {
                        ns: "data",
                      }),
                    )
                    .join(", ")}
                />
              )}
              {data?.drivingAreas && data?.drivingAreas.length > 0 && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="route"
                  iconColor="primary"
                  sublabel={t("offers.offer.candidates.account.driving-areas")}
                  width="100%"
                  label={data?.drivingAreas
                    ?.map((el: string) =>
                      t(el, {
                        ns: "data",
                      }),
                    )
                    .join(", ")}
                />
              )}
              {data?.workTypes && data?.workTypes.length > 0 && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="worktype"
                  iconColor="primary"
                  sublabel={t("offers.offer.candidates.account.work-types")}
                  width="100%"
                  label={data?.workTypes
                    ?.map((el: string) =>
                      t(el, {
                        ns: "data",
                      }),
                    )
                    .join(", ")}
                />
              )}
              {data?.directions && data?.directions?.length > 0 && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="map"
                  iconColor="primary"
                  sublabel={t("offers.offer.candidates.account.directions")}
                  width="100%"
                  label={data?.directions
                    ?.map((el: string) =>
                      t(el, {
                        ns: "data",
                      }),
                    )
                    .join(", ")}
                />
              )}
              {data?.citizenship && data?.citizenship?.length > 0 && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="earth-fill"
                  iconColor="primary"
                  sublabel={t("offers.offer.candidates.account.citizenship")}
                  width="100%"
                  label={data?.citizenship
                    ?.map((el: string) =>
                      t(`citizenship.${el}`, {
                        ns: "data",
                      }),
                    )
                    .join(", ")}
                />
              )}
              {data?.languages && data?.languages?.length > 0 && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="language"
                  iconColor="primary"
                  sublabel={t("offers.offer.candidates.account.languages")}
                  width="100%"
                  label={data?.languages
                    ?.map((el: string) =>
                      t(el, {
                        ns: "data",
                      }),
                    )
                    .join(", ")}
                />
              )}
            </InfoContent>
          </Wrapper>
        )}
      </>
    </Modal>
  )
}

export default OfferApplicationAccountModal
