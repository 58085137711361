import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { Label, P, Title } from "@jobintrans/base-ui.components.atoms.typography"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { useDispatch, useSelector } from "react-redux"
import { ProfileService } from "@jobintrans/base-ui.services.api-service"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { useTranslation } from "react-i18next"
import { RootState } from "store/store"
import { SentryService } from "services/SentryService"
import SelectRow from "@jobintrans/base-ui.components.molecules.select-row"
import Select from "@jobintrans/base-ui.components.atoms.select"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Switch from "@jobintrans/base-ui.components.atoms.switch"
import { locales } from "utils/lang.utils"

const ProfilePasswordView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["panel", "form", "common", "data"])
  const dispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      language: profile?.settings.language || "pl",
      applicationMessageTitle: profile?.settings.applicationMessage?.title || "",
      applicationMessage: profile?.settings.applicationMessage?.message || "",
    },
  })

  const [notifications, setNotifications] = useState({
    ...(profile?.settings.notifications || {
      aboutApplications: false,
    }),
  })

  const [applicationMessageActive, setApplicationMessageActive] = useState(
    !!profile?.settings.applicationMessage,
  )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit = useCallback(
    async (el: any) => {
      try {
        setLoading(true)
        setError(false)
        await ProfileService.updateSettings({
          language: el.language,
          notifications: notifications,
          applicationMessage: applicationMessageActive
            ? {
                title: el.applicationMessageTitle,
                message: el.applicationMessage,
              }
            : null,
        })
      } catch (e) {
        SentryService.error("[ERROR ProfileService.updatePassword]:", e)
        setError(true)
        return
      } finally {
        setLoading(false)
      }
    },
    [notifications, applicationMessageActive],
  )

  const onChange = (name: string, value: string | boolean) => {
    setNotifications(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  return (
    <Wrapper>
      <Box>
        <Title marginBottom="XS">{t("profile.settings.title")}</Title>
        <P small marginBottom="0">
          {t("profile.settings.text")}
        </P>
      </Box>
      <Content>
        {error && (
          <Alert type="error" small>
            {t("profile.password.error")}
          </Alert>
        )}
        <Box>
          <Label icon="bell" marginBottom="S">
            {t("profile.settings.notifications.title")}
          </Label>
          <Box>
            <SelectRow
              checked={notifications.aboutApplications}
              label={
                <Label type="secondary">
                  {t("profile.settings.notifications.options.aboutApplications")}
                </Label>
              }
              onClick={() => onChange("aboutApplications", !notifications.aboutApplications)}
            />
          </Box>
        </Box>
        <Box>
          <Label icon="language" marginBottom="S">
            {t("profile.settings.language.title")}
          </Label>
          <Select
            name="language"
            options={locales.map(lag => [
              t(`languages.${lag}`, {
                ns: "common",
              }),
              lag,
            ])}
            error={errors?.language}
            value={watch("language")}
            rhf={register("language", {
              required: t("common.form.required"),
            })}
          />
        </Box>
        <Box>
          <Row marginBottom="S" justifyContent="space-between" alignItems="center">
            <Label icon="send" marginBottom="0">
              {t("profile.settings.applicationMessage.title")}
            </Label>
            <Switch
              name="paymentContract"
              value={applicationMessageActive}
              onChange={({ name, value }) => setApplicationMessageActive(value)}
            />
          </Row>
          <Input
            name="name"
            value={watch("applicationMessageTitle")}
            rhf={register("applicationMessageTitle")}
            disabled={!applicationMessageActive}
            marginBottom="XS"
          />
          <Input
            name="name"
            value={watch("applicationMessage")}
            rhf={register("applicationMessage")}
            multiline
            rows={10}
            disabled={!applicationMessageActive}
          />
        </Box>
        <Row justifyContent="flex-end">
          <Button loading={loading} icon="save" onClick={handleSubmit(onSubmit)}>
            {t("save", { ns: "common" })}
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default ProfilePasswordView

const Content = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.M};
  max-width: 750px;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
